import { useState, ChangeEvent, FormEventHandler, FormEvent, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import api from '../../api/bw-api';
import { useAuth } from '../../context/auth';
import { User } from '@/global-components/types'
import { useNavigate } from 'react-router-dom';
import { Button } from "@/global-components/components/ui/button"
import { Input } from '@/global-components/components/ui/input'
import BwSymbol from '@/global-components/brand-assets/BwSymbol';
import { useToast } from "@/global-components/components/ui/use-toast";
import { Eye, EyeOff } from "lucide-react";
import helpers from '@/global-components/components/helpers';
export type SignupProps = {
}


interface ErrorMessage {
  message: string;
}

interface ErrorData {
  errors: {
      [key: string]: ErrorMessage[];
  };
}

const concatenateErrors = (data: ErrorData): string => {
  let errorCount = 0;
  let errorMessage = '';

  for (let key in data.errors) {
      if (data.errors.hasOwnProperty(key)) {
          for (let i = 0; i < data.errors[key].length; i++) {
              errorCount++;
              errorMessage += `${errorCount}. ${data.errors[key][i].message}\n`;
          }
      }
  }

  return errorMessage.trim();
}

const Signup = () => {
  const navigate = useNavigate();
  const { loggedIn, setLoggedIn, setUser } = useAuth();
  const { toast } = useToast()

  useEffect(() => {
    if (loggedIn) {
      navigate('/');
    }
  }, [loggedIn])

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [username, setUsername] = useState<string>('');
  const [firstname, setFirstname] = useState<string>('');
  const [firstnameError, setFirstnameError] = useState<string | null>(null);
  const [lastname, setLastname] = useState<string>('');
  const [lastnameError, setLastnameError] = useState<string | null>(null);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [passwordRepeatError, setPasswordRepeatError] = useState<string | null>(null);
  const [passwordRepeatVisible, setPasswordRepeatVisible] = useState<boolean>(false);

  const [dataValid, setDataValid] = useState<boolean>(false);

  const [register, { loading, error }] = useMutation(api.user.mutations.REGISTER, {
    onCompleted: data => {
      
      if (data?.registerAccount?.success) {
        toast({
          title: "Signup successful",
          description: "Please activate your account with the link in the e-mail we just sent you.",
          variant: "success"
        });
        // navigate('/');
        navigate('/login');
      } else {
        
        const errors: String = concatenateErrors(data.registerAccount);
        toast({
          title: "Signup unsuccessful",
          description: errors,
          variant: 'destructive',
          duration: 2000
        })
      }
    },
    onError: err => console.log(err),
  })

  const validateData = () => {
    const dataIsValid: boolean = !emailError && !firstnameError && !lastnameError && !passwordError && !passwordRepeatError && email.length > 0 && firstname.length > 0 && lastname.length > 0 && password.length > 0 && passwordRepeat.length > 0;
    setDataValid(dataIsValid);
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (helpers.isEmailValid(event.target.value)) {
      setEmailError(null);
      validateData();
    }
  }

  const handleEmailBlur = () => {
    setEmailError(helpers.isEmailValid(email) ? null : 'Invalid e-mail');
    validateData()
  }

  const isFirstNameValid = (_firstname: string): boolean => {
    return _firstname.length >= 1 ? true : false;
  }

  const handleFirstnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstname(event.target.value);
    if (isFirstNameValid(event.target.value)) {
      setFirstnameError(null)
      validateData()
    }
  }

  const handleFirstnameBlur = () => {
    setFirstnameError(isFirstNameValid(firstname) ? null : 'First name is required');
    validateData()
  }

  const isLastNameValid = (_lastname: string) => {
    return _lastname.length >= 1 ? true : false;
  }

  const handleLastnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value);
    if (isLastNameValid(event.target.value)) {
      setLastnameError(null)
      validateData()
    }
  }

  const handleLastnameBlur = () => {
    setLastnameError(isLastNameValid(lastname) ? null : 'Last Name is required');
    validateData()
  }

  const isPaswordValid = (_password: string): boolean => {
    return (_password && _password.length >= 8 && /[A-Z]/.test(_password) && /\d/.test(_password)) ? true : false;
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (isPaswordValid(event.target.value)) {
      setPasswordError(null)
      validateData()
    }
  }

  const handlePasswordBlur = () => {
    setPasswordError(isPaswordValid(password) ? null : 'Password needs at least 8 characters, one upper case and one number.')
    validateData()
  }

  const isPasswordRepeatValid = (_passwordRepeat: string): boolean => {
    return password === _passwordRepeat ? true : false;
  }

  const handlePasswordRepeatChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordRepeat(event.target.value);
    if (isPasswordRepeatValid(event.target.value)) {
      setPasswordRepeatError(null)
      validateData()
    }
  }

  const handlePasswordRepeatBlur = () => {
    setPasswordRepeatError(isPasswordRepeatValid(passwordRepeat) ? null : 'Passwords must match.')
    validateData()
  }

  const submitRegister = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    register({variables: {email: email.toLowerCase(), password1: password, password2: passwordRepeat, firstName: firstname, lastName: lastname, username: firstname.toLowerCase() + '-' + Math.round(Math.random()*1000)} });
  }

  useEffect(() => {
    validateData()
  }, [emailError, passwordError, passwordRepeatError, firstnameError, lastnameError])

  return (
    <div className='view register flex flex-col items-center justify-center'>
      <BwSymbol className='w-16 h-16 mb-4 mt-8 fill-bw-green' />  
      <h1 className='text-center font-bold'>Batch.Works</h1>
      <span className='text-center mb-16 text-xs text-bw-green opacity-30 italic'>Beta</span>
      <div className='login-container w-[250px]'>
        <form onSubmit={submitRegister} autoComplete="off" className='mb-16 flex flex-col gap-1 w-full'>
          <Input type='email' tabIndex={1} name='email' placeholder={emailError ? emailError : "Email"} value={email} onChange={handleEmailChange} onBlurCapture={handleEmailBlur} className={emailError ? 'border-ui-denial-red/50' : ''} />
          <div className={`password-hint text-xs text-ui-denial-red mb-2 pl-2 ${emailError ? '' : 'hidden'}`}>This email isn't valid</div>
          <div className='relative'>
            <Input type={passwordVisible ? 'text' : 'password'} tabIndex={2} name='password' placeholder={passwordError ? passwordError : "Password"} value={password} onChange={handlePasswordChange} onBlurCapture={handlePasswordBlur} className={passwordError ? 'border-ui-denial-red/50' : ''} />
            <Button type='button' tabIndex={-1} variant='minimal' size='xs' className='absolute m-auto top-0 bottom-0 right-1' onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? 
                <EyeOff className='h-4 w-4'/>
                : <Eye className='h-4 w-4'/>
              }
            </Button>
          </div>
          <div className={`password-hint text-xs text-ui-denial-red mb-2 pl-2 ${passwordError ? '' : 'hidden'}`}>8 characters, 1 upper case, 1 number</div>
          <div className='relative'>
            <Input type={passwordRepeatVisible ? 'text' : 'password'} tabIndex={3} name='password' placeholder={passwordRepeatError ? passwordRepeatError : "Repeat Your Password"} value={passwordRepeat} onChange={handlePasswordRepeatChange} onBlurCapture={handlePasswordRepeatBlur} className={passwordRepeatError ? 'border-ui-denial-red/50' : ''} />
            <Button type='button' tabIndex={-1} variant='minimal' size='xs' className='absolute m-auto top-0 bottom-0 right-1' onClick={() => setPasswordRepeatVisible(!passwordRepeatVisible)}>
              {passwordRepeatVisible ? 
                <EyeOff className='h-4 w-4'/>
                : <Eye className='h-4 w-4'/>
              }
            </Button>
          </div>
          <div className={`password-hint text-xs text-ui-denial-red mb-2 pl-2 ${passwordRepeatError ? '' : 'hidden'}`}>Passwords must match</div>
          <Input type='text' tabIndex={4} name='firstname' placeholder={firstnameError ? firstnameError : "First name"} value={firstname} onChange={handleFirstnameChange} onBlurCapture={handleFirstnameBlur} autoComplete="first-name" className={firstnameError ? 'border-ui-denial-red/50 placeholder-ui-denial-red' : ''} />
          <Input type='text' tabIndex={5} name='lastname' placeholder={lastnameError? lastnameError : "Last Name"} value={lastname} onChange={handleLastnameChange} onBlurCapture={handleLastnameBlur} className={lastnameError ? 'border-ui-denial-red/50' : ''} />
          <Button disabled={!dataValid} tabIndex={6} variant="bwprimary" type="submit" className='mt-4'>Register</Button>
        </form>
      </div>
    </div>
  )
}

export default Signup;