import { Button } from "@/global-components/components/ui/button";
import { Checkbox } from "@/global-components/components/ui/checkbox"
import PrintProduct from './printProduct/PrintProduct';
import api from '../../api/bw-api';
import { useMutation, useQuery } from '@apollo/client';
import { useToast } from "@/global-components/components/ui/use-toast";
import { CircleDollarSign, Diameter, Route, Timer, Weight, X, XCircle } from "lucide-react";
import DownloadButton from '@/global-components/components/bw/DownloadButton'
import { Spin } from "react-cssfx-loading";
import { FileAtrributes, FileType } from "@/global-components/types";
import { useEffect, useState } from "react";


type GcodeFileInfo = {
  file: FileType;
  showActions: boolean;
  slicerSettingsOpen?: boolean;
  slicedExternally?: boolean;
  user?: any;
  index?: number;
  updating?: boolean;
  callback?: Function;
  product?: any;
  showDetails?: boolean;
  showCheckbox?: boolean;
}

const GcodeFileInfo = (props: GcodeFileInfo) => {
  const [file, setFile] = useState<FileType | undefined>(props.file)
  const [fileAttributes, setFileAttributes] = useState<FileAtrributes | undefined>(undefined)

  const user: any = props.user;
  const index: number | undefined = props.index;
  const showActions: boolean = props.showActions;
  const showCheckbox: boolean = props.showCheckbox ? true : false;
  const callback: Function | undefined = props.callback;
  const showDetails: boolean | undefined = props.showDetails;
  const product: any | undefined = props.product;

  const { toast } = useToast()
  const fileAttributesQuery = useQuery(api.products.queries.GET_FILE_ATTRIBBUTES_FOR_FILE, {
    variables: {
      fileId: file?.fileId,
      skip: !file
    }
  })
  const [deleteFileOnServer, deleteFileOnServerResults] = useMutation(api.products.mutations.DELETE_FILE);

  useEffect(() => {
    setFile(props.file)
    fileAttributesQuery.refetch()
  }, [props.file])

  useEffect(() => {
    if (fileAttributesQuery.data?.fileAttributesForFile) {
      setFileAttributes(fileAttributesQuery.data?.fileAttributesForFile)
    }
  }, [fileAttributesQuery.data])

  /* potentially depricated */
  const deleteFile = (file: any) => {
    deleteFileOnServer({variables: {fileId: file.fileId}})
      .then((result: any) => {
        
        if (result.data.deleteFile.success) {
          if (callback) {
            callback();
          }
          toast({
            title: 'Delete successfully',
            description: file.fileName + ' deleted successfully.'
          })
        }
      })
      .catch((error : any) => { 
        toast({
          title: 'Delete failed',
          variant: 'destructive',
          description: "We're sorry, but unfortunately '" + file.fileName + "' could not be deleted. Please try again or contact software@batch.works, or message staff from within the product.",
        })
        
      })
  }

  const minutesToMMHH = (minutes: string | number): string => {
    const minutesAsNumber: number = typeof minutes === 'string' ? parseFloat(minutes) : minutes;
    const h: number = minutesAsNumber >= 60 ? Math.floor(minutesAsNumber/60) : 0
    const m: number = minutesAsNumber % 60
    return h.toString() + ':' + m.toString()
  }

  if (!file) {
    return null
  }

  return (
    <div 
    className='file-preview flex justify-between items-center rounded-md border border-bw-pale-sage bg-white px-4 py-3 font-mono text-sm hover:border-transparent'
    key={index}>
      <div className='file-info'>
        <div className="flex items-center gap-2">
          {file.fileName}
          {props.updating && <Spin className="inline-spin h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" />}
        </div>
        {showDetails ?
          (fileAttributes ?
            <div className='text-xs text-bw-green flex items-center gap-3 mt-1'>
              <span className="flex gap-1 items-center" title="Nozzle diameter"><Diameter className="h-4 w-4 opacity-30"/> {fileAttributes.nozzleSize}mm</span>
              <span className="flex gap-1 items-center" title="Estimated printing time"><Timer className="h-4 w-4 opacity-30"/>{minutesToMMHH(fileAttributes.printTime)}h</span>
              <span className="flex gap-1 items-center" title="Estimated material weight"><Weight className="h-4 w-4 opacity-30" />{fileAttributes.printWeight}g</span>
              {file.costmodelSet ?
                <span className="flex gap-1 items-center" title="Estimated cost"><CircleDollarSign className="h-4 w-4 opacity-30" /> £{file.costmodelSet[0]?.grossPrice}</span>
                : null
              }
              {fileAttributes.outOfBounds &&
                <span className="flex gap-1 text-ui-denial-red">| Out Of Bounds</span>
              }
            </div>
          : 
            <div className="text-xs flex items-center gap-1">
              <Spin className="inline-spin h-2 w-2" color="#36463D" width="20px" height="20px" duration="0.3s" /> Loading file attributes
            </div>
          )
          : null
        }
      </div>
      {showActions ?
        <div className='flex gap-2'>
          {/* <Button variant='destructiveoutline' size='smallicon' onClick={() => deleteFile(file)}><XCircle className='h-4 w-4'></XCircle></Button> */}
          {!props.slicedExternally && 
            <Button variant='outline' size='sm' className='gap-2 text-xs' onClick={() => callback ? callback() : null}>
              {props.slicerSettingsOpen ? 
                <X className="h-4 w-4" /> 
                : <Route className='h-4 w-4' />} 
              {props.slicerSettingsOpen ? 
                'Settings' 
                : 'Settings'}
            </Button>
          }
          {showCheckbox ? null : <DownloadButton url={file.presignedUrl} name={file.fileName}/>}
        </div>
        : null
      }
      {showCheckbox ?
        <div>
          <Checkbox onCheckedChange={(checked: boolean) => callback ? callback(checked) : null}/>
        </div>
        : null  
      }
    </div>
  )
}

export default GcodeFileInfo;
