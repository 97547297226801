import React, { useState, useEffect, ReactNode } from 'react';
import { Route, Routes, useNavigate, useLocation, Navigate, Outlet } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { Toaster } from "@/global-components/components/ui/toaster"

import './App.scss';
import api from './api/bw-api';
import AuthContext from './context/auth';
import Home from './views/home/Home';
import Account from './views/account/Account';
import Login, { LoginProps } from './views/login/Login';
import Signup, { SignupProps } from './views/signup/Signup';
import VerifyAccountEmail from './views/verifyAccountEmail/VerifyAccountEmail'
import Product from './views/product/Product';
import Navbar, { NavbarProps } from './components/navbar/Navbar';
import QuickPrint from './components/quickPrint/QuickPrint';
import SetNewPasswordWithToken from './views/SetNewPasswordWithToken/SetNewPasswordWithToken';
// import UserInternetConnectionScore from '@/global-components/components/UserInternetConnectionScore'

import { useVerifyTokenAndGetUser} from './services/user'

import { User, UserPreferences } from '@/global-components/types'
import MaterialsView from './views/material/MaterialsView';
import SchoolView from './views/school/SchoolView';
import Project from './views/project/Project';
import helpers from './global-components/components/helpers';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const verifyTokenAndGetUser = useVerifyTokenAndGetUser();

  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Replace 'your_password' with your desired password
    if (password === 'ag23') {
      setAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  useEffect(() => {
    helpers.removeUnusedFilesFromLocalStorage()

    const handleNotLoggedIn = () => {
      if (location.pathname !== '/signup') navigate("/login");
    }
    const init = async ()  => { 
      if(api.user.local.isTokenSet()) {
        try {
          const userData: User = await verifyTokenAndGetUser(api.user.local.getToken())
          setUser(userData);
          setLoggedIn(true);
        } catch (err) {
          
          // handleNotLoggedIn();
        } finally {
          setLoading(false);
        }
      } else {
       //  handleNotLoggedIn();
        setLoading(false);
      }
    }

    init();
  }, [])

  interface ProtectedRouteProps {
    children: ReactNode;
  }

  const PrivateRoute = () => {
    if (!loggedIn) {
      return <Navigate to="/login" replace />;
    }
    return <Outlet />;
  };


  return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn, user, setUser }}>
      <div className="dw">
        {/* <UserInternetConnectionScore updateInterval={5000} /> */}
        {user?.isStaff ? <QuickPrint /> : null}
        <Navbar />
        {!loading && (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify/:authToken" element={<VerifyAccountEmail />} />
            <Route path="/reset/:resetToken" element={<SetNewPasswordWithToken />} />
            <Route element={<PrivateRoute/>}>
              <Route path="/" element={<Home />} />
              <Route path="/account" element={<Account />}/>
              <Route path="/product/:productRef" element={<Product />} />
              <Route path="/project/:groupRef" element={<Project />} />
              <Route path="/materials" element={<MaterialsView />} />
              <Route path="/school" element={<SchoolView />} />
              <Route path="*" element={<Home />} />
            </Route>
          </Routes>
          )}
      </div>
      <Toaster />
    </AuthContext.Provider>
  );
}

export default App;
